import React from 'react';
import PropTypes from 'prop-types';

import { GoogleMapsIcon } from './Icons';

const Location = ({ content }) => (
  <dl className="break-inside-avoid-column mb-16 prose prose-custom">
    <dt className="sr-only">Name</dt>
    <dd className="pl-0"><h3 className="mt-0">{content.title}</h3></dd>
    {content.physicalAddress && (
      <>
        {/*<dt className="font-trade mt-4 mb-2 flex"><MapPinIcon className="h-5 w-5" aria-hidden="true" /><span className="ml-1">Physical address</span></dt>*/}
        {/* 24px > 32px, lh 28px > 40px */}
        <dt className="font-trade text-[1rem] sm:text-xs 2xl:text-[1.3rem] leading-[1.4] 2xl:leading-[1.2] mt-4 mb-2">Physical address</dt>
        <dd className="text-xs">
          <address dangerouslySetInnerHTML={{ __html: content.physicalAddress }} />
          {content.googleMaps && (
            <a className="block flex items-center my-4 -ml-1" href={content.googleMaps} rel="nofollow noreferrer"><GoogleMapsIcon className="h-6 w-6" aria-hidden="true" /><span className="ml-1">Map</span></a>
          )}
        </dd>
      </>
    )}
    {content.mailingAddress && (
      <>
        <dt className="font-trade text-[1rem] sm:text-xs 2xl:text-[1.3rem] leading-[1.4] 2xl:leading-[1.2] mt-8 mb-2">Mailing address</dt>
        <dd className="text-xs"><address dangerouslySetInnerHTML={{ __html: content.mailingAddress }} /></dd>
      </>
    )}
    {content.email && (
      <>
        <dt className="font-trade text-[1rem] sm:text-xs 2xl:text-[1.3rem] leading-[1.4] 2xl:leading-[1.2] mt-8 mb-2">Email</dt>
        <dd className="text-xs"><a href={`mailto:${content.email}`}>{content.email}</a></dd>
      </>
    )}
  </dl>
);

Location.propTypes = {
  content: PropTypes.shape({}).isRequired,
};

export default Location;
