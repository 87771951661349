
import Image1 from "../images/placeholder-4-3.png";
import Image2 from "../images/placeholder-4-3-portrait.png";
import Image3 from "../images/placeholder-style0-4-3.png";
import Image4 from "../images/placeholder-style0-4-3-portrait.png";
import Image5 from "../images/placeholder-16-9.png";
import Image6 from "../images/placeholder-style0-16-9.png";

export const placeholderImages = [
  { url: Image1 },
  { url: Image2 },
  { url: Image3 },
  { url: Image4 },
  { url: Image5 },
  { url: Image6 },
];

export const menu = [
  {
    id: 0,
    label: 'About Us',
    path: '/about/',
    prismicId: 'X6_DxBIAACcAP7w5',
    children: [
      {
        id: 0,
        label: 'Fact Sheet',
        selected: false,
        path: '/about/fact-sheet',
        prismicId: 'X8UTfxYAACsAp1rb',
      },
      {
        id: 1,
        label: 'Investment Criteria',
        selected: false,
        path: '/about/investment-criteria',
        prismicId: 'X6_QZhIAACYAP_Pj',
      },
      {
        id: 2,
        label: 'Team',
        selected: false,
        path: '/about/team',
        prismicId: 'X8TXqxYAACsApk5k',
      },
      {
        id: 3,
        label: 'Sustainable Finance Disclosures',
        selected: false,
        path: '/about/sustainable-finance-disclosures',
        prismicId: 'Yby03BEAACEA1ge5',
      },
    ],
  },
  {
    id: 1,
    label: 'Impact and SDGs',
    path: '/impact',
    prismicId: 'X8UFHxYAACwApxnb',
    children: [
      {
        id: 0,
        label: 'Impact Reports and SDGs',
        selected: false,
        path: '/impact/social-reports',
        prismicId: 'X8UF_BYAAC0Apx25',
      },
      {
        id: 1,
        label: 'Meet Our Clients',
        selected: false,
        path: '/impact/meet-our-clients',
        prismicId: 'X8UGjBYAACwApyBO',
      },
    ],
  },
  {
    id: 2,
    label: 'Partners',
    path: '/partners',
    prismicId: 'X8T73hYAACsApvCt',
    children: [
      {
        id: 0,
        label: 'Investees',
        selected: false,
        path: '/partners/investees',
        prismicId: 'X8T9MRYAACoApvaT',
      },
      {
        id: 1,
        label: 'Climate Smart Fund',
        selected: false,
        path: '/partners/climate-smart-fund',
        prismicId: 'ZzOIZhAAACYAwmeY',
      },
      {
        id: 2,
        label: 'Investors and Partners',
        selected: false,
        path: '/partners/investors',
        prismicId: 'X8T-_RYAACoApv6b',
      },
    ],
  },
  {
    id: 3,
    label: 'News',
    path: '/news',
    prismicId: null,
    children: [],
  },
  {
    id: 4,
    label: 'Videos',
    path: '/videos',
    prismicId: null,
    children: [],
  },
  {
    id: 5,
    label: 'Media',
    path: '/in-the-media',
    prismicId: 'X8T3XxYAACoAptyT',
    children: [],
  },
  {
    id: 6,
    label: 'Contact',
    path: '/contact',
    prismicId: null,
    children: [],
  },
];

export const socialPages = [
  {
    name: 'LinkedIn',
    url: 'https://linkedin.com/company/nordic-microfinance-initiative',
  },
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/ablernordic',
  },
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/@AblerNordic',
  },
  {
    name: 'X (Twitter)',
    url: 'https://twitter.com/Abler_Nordic',
  },
];

export const offices = [
  {
    title: 'Norway',
    physicalAddress: `Rosenkrantz' gate 22, 7th Floor<br />N-0160 Oslo<br />Norway`,
    googleMaps: 'https://goo.gl/maps/usxdXmnCXiDazJEJA',
    mailingAddress: `P.O Box 1925, Vika<br />N-0125 Oslo<br />Norway`,
    email: 'info@ablernordic.com',
  },
  {
    title: 'Denmark',
    physicalAddress: `c/o IFU<br />Fredericiagade 27<br />1310 Copenhagen K<br />Denmark`,
    googleMaps: 'https://goo.gl/maps/3yormo3K8NitJFJX6',
    mailingAddress: ``,
    email: '',
  },
  {
    title: 'Kenya',
    physicalAddress: `c/o Norfund<br />14th Floor, The Address<br />Muthangari Drive<br />Nairobi<br />Kenya`,
    googleMaps: 'https://goo.gl/maps/zc41DyS8irCgSSYDA',
    mailingAddress: `Post box 66162<br />Westlands<br />00800 Nairobi<br />Kenya`,
    email: '',
  },
  {
    title: 'India',
    physicalAddress: `c/o Spring House Coworking Private Ltd<br />LG/UG 006, Grand Mall, M.G Road<br />Gurugram, Haryana 122002<br />India`,
    googleMaps: 'https://goo.gl/maps/UacTnHPEQZkGMxYv5',
    mailingAddress: ``,
    email: '',
  },
  {
    title: 'Indonesia',
    physicalAddress: `Buenos Aires, i2 no2,<br />Delatinos. Jl. Setapak No.70,<br />Tangerang Selatan,<br />Banten District<br />Indonesia`,
    googleMaps: '',
    mailingAddress: `Tangerang Selatan,<br />Banten District<br />15314<br />Indonesia`,
    email: '',
  },
];
